<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"

export default {
  name: "GraphPerDay",
  mixins: [tabMixin],
  computed: {
    statsFiltered() {
      return this.page.stats.acessosLeilao.slice(-6).reverse()
    }
  },
  methods: {
    parseData (data) {
      data = String(data)
      const ano = data.substring(0, 4)
      const mes = data.substring(4, 6)
      const dia = data.substring(6, 8)
      const dataObj = new Date(`${ano}-${mes}-${dia}`)
      const options = { month: 'short' }
      const nomeMes = dataObj.toLocaleDateString('pt-BR', options).replace('.', '')
      return `${dia} ${nomeMes.charAt(0).toUpperCase() + nomeMes.slice(1)}`
    }
  }
}
</script>

<template>
  <div class="graph-per-day">
    <div class="item" v-for="s in statsFiltered" :key="s.data">
      <div class="graph">
        <div class="count">{{ s.acessos|number }}</div>
        <span class="graph-val" :style="`height: ${(s.porcentagem2 / 100) * 130}px`"></span>
        <div class="lbl">{{parseData(s.data)}}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.graph-per-day {
  display flex
  justify-content space-between
  height 100%
  padding 0 10px
  flex 1
  user-select none

  .item {
    display flex
    align-items flex-end
    justify-content space-between
    margin-bottom 15px
    flex 1

    &:last-child {
      .graph-val {
        background-color #6489F7 !important
      }
    }

    .lbl {
      width 32px
      font-size 12px
      color #9DA9BD
      text-align center
      font-weight 500
      padding-top 5px
    }

    .graph {
      flex 1
      display flex
      flex-direction column
      justify-content space-between
      align-items center

      .count {
        font-size 10px
        color #000000
        font-weight bold
        padding-bottom 5px
      }

      .graph-val {
        display block
        position relative
        border-radius 8px
        background-color #EBEFF4
        width 24px
        transition all 200ms
        &:hover {
          width 26px
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16)
        }
      }
    }
  }
}
</style>
