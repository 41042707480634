<template>
  <div class="ev2-box leilao-resumo">
    <div class="header">
      <div class="hcol1">
        <div class="title">Resumo</div>
        <div class="subtitle">Informações sobre o leilão</div>
      </div>
      <div class="hcol2">
      </div>
    </div>
    <div class="body no-overflow m-t-md">
      <div class="tab1">
        <div class="lbl">Robô</div>
        <div class="val">
          <span v-if="leilao.controleAutomatico" class="ok">LIGADO <i class="fal fa-robot text-black"/></span>
          <div v-else class="nok">DESLIGADO <span class="text-grey font-11 m-l">(controle manual)</span></div>
        </div>
      </div>
      <div class="tab1">
        <div class="lbl">Datas</div>
        <div class="val leilao-datas-str">
          <div v-for="numeroLeilao in leilao.instancia" :key="'leilao-nro-' + numeroLeilao"
               :class="parseDataClass(numeroLeilao)">
            {{ leilao['data' + numeroLeilao]|formatDate }} <span class="text-grey font-10 m-l-xs capitalize" style="font-weight: 400">{{dia(leilao['data' + numeroLeilao])}}</span>
            <!--<span class="text-grey font-10 m-l" style="font-weight: 400">{{leilao['data' + numeroLeilao]|formatDateDistance}}</span>-->
          </div>
        </div>
      </div>
      <div class="tab1">
        <div class="lbl">Leilão atual</div>
        <div class="val">
          <leilao-data/>
        </div>
      </div>
      <div class="tab1">
        <e-row>
          <e-col>
            <div class="lbl">Cronômetro</div>
            <div class="val ok">
              {{formatTime(leilao.timerPregao)}}
            </div>
          </e-col>
          <e-col>
            <div class="lbl">Encerrar primeiro lote em</div>
            <div class="val">
              {{opcoesEncerramento}}
            </div>
          </e-col>
        </e-row>
      </div>
      <div class="tab1">
        <e-row>
          <e-col>
            <div class="lbl">Lance automático</div>
            <div class="val ok" v-if="leilao.lanceAutomatico">Sim</div>
            <div class="val nok" v-else>Não</div>
          </e-col>
          <e-col>
            <div class="lbl">Lance parcelado</div>
            <div class="val ok" v-if="leilao.permitirParcelamento">Sim</div>
            <div class="val nok" v-else>Não</div>
          </e-col>
        </e-row>
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import LeilaoData from "components/leiloes/components/layout-components/LeilaoData.vue"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import {addSeconds, format, parse, isToday} from "date-fns"
import {convertDate} from "@/utils/date"

export default {
  name: "Resumo",
  components: {
    ECol,
    ERow,
    LeilaoData
    //ERow
  },
  mixins: [tabMixin],
  computed: {
    opcoesEncerramento () {
      try {
        const cronometro = Number(this.leilao.timerPregao)
        const praca = Number(this.leilao.praca)
        const controleTempoInicial = Number(this.leilao.controleTempoInicial)
        const datas = []
        datas[1] = this.leilao.data1
        datas[2] = this.leilao.data2
        datas[3] = this.leilao.data3
        if (typeof datas[praca] !== 'undefined' && datas[praca]) {
          const dt = convertDate(datas[praca], true)
          const hora = dt[1]
          const horarioExato = parse(hora, 'HH:mm', new Date())
          const horarioFinal = addSeconds(parse(hora, 'HH:mm', new Date()), cronometro)
          const v = [
            {label: format(horarioExato, 'HH:mm:ss'), value: 0},
            {label: format(horarioFinal, 'HH:mm:ss'), value: cronometro},
          ]
          console.log(v.find((c => c.value === controleTempoInicial)))
          return dt[0] + ' ' + v.find((c => c.value === controleTempoInicial)).label
        }
        return 'Inválido'
      } catch (e) {
        return 'Inválido'
      }
    }
  },
  methods: {
    parseDataClass(n) {
      const pracaAtual = Number(this.leilao.praca)
      if (n === pracaAtual) {
        return 'leilao-atual'
      }
      if (n < pracaAtual) {
        return 'leilao-passado'
      }
      if (n > pracaAtual) {
        return 'leilao-futuro'
      }
    },
    formatTime(seconds) {
      if (seconds < 60) {
        return `${seconds} segundos`
      } else {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60

        if (remainingSeconds === 0) {
          return `${minutes} minuto${minutes > 1 ? 's' : ''}`
        } else {
          return `${minutes} minuto${minutes > 1 ? 's' : ''} e ${remainingSeconds}s}`
        }
      }
    },
    dia (d) {
      if (d.date) {
        d = d.date
      }
      try {
        const data = new Date(d)
        let diaSemana
        if (isToday(data)) {
          diaSemana = 'Hoje'
        } else {
          diaSemana = data.toLocaleDateString('pt-BR', { weekday: 'long' })
        }
        return diaSemana
      } catch (e) {
        console.error(e)
        return ''
      }
    }
  }
}
</script>
