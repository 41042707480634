import uploadDocumento from "components/leiloes/windows/uploadDocumento"
import {deleteDocumentoLeilao, downloadDocumentoLeilao} from "@/domain/leiloes/services"

export default {
    methods: {
        uploadDocumento: uploadDocumento,
        excluir (arquivo) {
            this.$uloc.dialog({
                title: 'Você está prestes a remover um arquivo',
                message: 'Você tem certeza que deseja remover este arquivo do cadastro do leilão? Esta operação não pode ser desfeita.',
                ok: 'Sim',
                cancel: 'Não',
                color: 'negative'
            }).then(() => {
                console.log('Removendo...', arquivo)
                deleteDocumentoLeilao(this.leilao.id, arquivo.id)
                    .then(response => {
                        console.log(response.data)
                        this.leilao.documentos.splice(this.leilao.documentos.indexOf(arquivo), 1)
                        this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Arquivo excluído com sucesso.'})
                        this.load && this.load()
                    })
                    .catch((error) => {
                        console.log(error)
                        this.alertApiError(error)
                    })
            }).catch(() => {
            })
        },
        download (event, arquivo, force = false) {
            if (arquivo.permissao > 1 || force) {
                event.preventDefault()
                event.stopPropagation()
                arquivo.downloading = true
                downloadDocumentoLeilao(this.leilao.id, arquivo.id)
                    .then(response => {
                        console.log(response.data)
                        const link = document.createElement('a')
                        link.href = response.data.url
                        document.body.appendChild(link)
                        link.click();
                        window.setTimeout(() => {
                            document.body.removeChild(link)
                            arquivo.downloading = false
                        }, 100)
                    })
                    .catch(error => {
                        arquivo.downloading = false
                        this.alertApiError(error)
                    })
            }
        }
    }
}
