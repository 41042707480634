<template>
  <div class="ev2-box stats stats-leilao">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatísticas do Leilão</div>
        <div class="subtitle">Resumo de informações sobre o evento</div>
      </div>
      <div class="hcol2">
      </div>
    </div>
    <div class="body m-t-lg" v-if="page.loadingStats">
      <u-fake-loader />
      <u-fake-loader width="50%" />
      <u-fake-loader width="90%" />
      <br>
      <br>
      <br>
      <u-fake-loader />
    </div>
    <div v-else class="body m-t">
      <section class="cont-itensSolicitacoes flexC-base">
        <div class="info-count">
          <p><span>Total de <small>habilitados:</small></span></p>
          <strong>{{ leilao.habilitados|number }}</strong>
        </div>

        <div class="cont-graph m-t-sm">
          <h6>Gráfico de visitas</h6>
          <div v-if="!page.stats || !page.stats.acessosLeilao || !page.stats.acessosLeilao.length" class="m-t" style="min-height: 150px">
            Nenhum dado para estatística
          </div>
          <div style="min-height: 150px; display: flex; justify-content: space-between; flex-direction: column" v-else>
            <graph-per-day />
          </div>
        </div>

        <div class="info-count m-b">
          <p><span>Total de <small>visitas</small> ao leilão:</span></p>
          <strong>{{ (pctAcessoDispositivos.total || 0)|abreviarNumero }}</strong>
        </div>

        <div class="grid-infos">
          <div class="g-item g-1">
            <div class="c-left">
              <i class="fal fa-mobile" />
            </div>

            <div class="c-right flexC-base">
              <span>Mobile</span>
              <strong>{{ pctAcessoDispositivos.mobile|abreviarNumero }}<small class="">{{ pctAcessoDispositivos.mobilePct }}%</small></strong>
            </div>
          </div><!-- END Grid 1 -->

          <div class="g-item g-2">
            <div class="c-left">
              <i class="fal fa-display" />
            </div>

            <div class="c-right flexC-base">
              <span>Desktop</span>
              <strong>{{ pctAcessoDispositivos.desktop|abreviarNumero }}<small class="">{{ pctAcessoDispositivos.desktopPct }}%</small></strong>
            </div>
          </div><!-- END Grid 2 -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {UFakeLoader} from "uloc-vue"
import GraphPerDay from "components/leiloes/components/dashboard/includes/GraphPerDay.vue";

export default {
  name: "EstatisticaLeilao",
  components: {
    GraphPerDay,
    // ECol,
    // ERow,
    UFakeLoader
  },
  mixins: [tabMixin],
  computed: {
    pctAcessoDispositivos () {
      const acessos = this.page.stats.acessosLeilaoTotal
      if (!acessos) {
        return {
          mobile: 0,
          desktop: 0
        }
      }
      const acessosLeilao = Number(acessos.acessos) || 0
      const acessosLeilaoMobile = Number(acessos.acessosMobile) || 0
      const acessosLeilaoDesktop = Number(acessos.acessosDesktop) || 0

      const totalAcessos = acessosLeilaoMobile + acessosLeilaoDesktop

      const porcentagemMobile = totalAcessos > 0 ? Math.round((acessosLeilaoMobile / totalAcessos) * 100) : 0
      const porcentagemDesktop = totalAcessos > 0 ? Math.round((acessosLeilaoDesktop / totalAcessos) * 100) : 0
      return {
        total: acessosLeilao,
        mobile: acessosLeilaoMobile,
        desktop: acessosLeilaoDesktop,
        mobilePct: porcentagemMobile,
        desktopPct: porcentagemDesktop
      }
    }
  }
}
</script>
