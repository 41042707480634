<template>
  <div class="ev2-box stats stats-lotes">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatísticas de Lotes</div>
        <div class="subtitle">Informações sobre acesso aos lotes</div>
      </div>
      <div class="hcol2">
      </div>
    </div>
    <div class="body m-t-lg" v-if="page.loadingStats">
      <u-fake-loader />
      <u-fake-loader width="50%" />
      <u-fake-loader width="90%" />
      <br>
      <br>
      <br>
      <u-fake-loader />
    </div>
    <div v-else class="body m-t">
      <section class="cont-itensSolicitacoes flexC-base">
        <div class="info-count">
          <p><span>Total de <small>lotes:</small></span></p>
          <strong>{{ leilao.totalLotes|number }}</strong>
        </div>
      </section>
      <div class="cont-graph m-t">
        <h6>Lotes mais visitados</h6>
      </div>
      <div class="lotes-stats">
        <div v-for="s in page.stats.acessosLotesTop5" :key="s.idLote" class="client">
          <div class="lbl" style="cursor: default">
            Lote {{s.numeroString || s.numero}}
            <u-tooltip :offset="[12,12]" anchor="bottom left" self="top left">
              {{s.porcentagem|number}}%
            </u-tooltip>
          </div>
          <div class="graph">
            <span class="graph-val" :style="`width: ${s.porcentagem2}%`"></span>
            <span class="count">{{ s.acessos|abreviarNumero }}</span>
          </div>
        </div>
      </div>
      <div class="def-count">
        <div class="count m-t-lg">
          <div class="lbl">Soma da visita em todos os lotes:</div>
          <div class="val-container">
            <div class="value">{{page.stats.acessosLotes|abreviarNumero}}</div>
          </div>
        </div>
        <div v-if="page.stats.loteLancesTop3 && page.stats.loteLancesTop3.length" class="count full">
          <div class="lbl">Com mais lances:</div>
          <div class="val-container">
            <div class="value small"><strong>{{ page.stats.loteLancesTop3[0].numeroString ?? page.stats.loteLancesTop3[0].numero }}</strong> com {{page.stats.loteLancesTop3[0].lances}} lance{{ Number(page.stats.loteLancesTop3[0].lances) > 1 ? 's' : '' }}</div>
          </div>
        </div>
        <div v-if="page.stats.loteParticipantesTop3 && page.stats.loteParticipantesTop3.length" class="count full">
          <div class="lbl">Com mais participantes::</div>
          <div class="val-container">
            <div class="value small"><strong>{{ page.stats.loteParticipantesTop3[0].numeroString ?? page.stats.loteParticipantesTop3[0].numero }}</strong> com {{page.stats.loteParticipantesTop3[0].pessoas}} pessoa{{Number(page.stats.loteParticipantesTop3[0].pessoas) > 1 ? 's' : ''}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {UFakeLoader} from "uloc-vue"

export default {
  name: "EstatisticaLotes",
  components: {
    // ECol,
    // ERow,
    UFakeLoader
  },
  mixins: [tabMixin],
  computed: {
  }
}
</script>
