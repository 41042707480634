<template>
  <div class="ev2-box stats stats-resumo">
    <div class="header">
      <div class="hcol1">
        <div class="title">Resumo de Habilitações</div>
        <div class="subtitle">Informações sobre Habilitados</div>
      </div>
      <div class="hcol2">
      </div>
    </div>
    <div class="body m-t-lg" v-if="page.loadingStats">
      <u-fake-loader />
      <u-fake-loader width="50%" />
      <u-fake-loader width="90%" />
      <br>
      <br>
      <br>
      <u-fake-loader />
    </div>
    <div v-else class="body m-t">
      <div class="def-count">
        <div class="count">
          <div class="lbl">Total de solicitações:</div>
          <div class="val-container">
            <div class="value homolog">{{ page.stats.habilitacoesLeilao.total ?? 0 }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Habilitados:</div>
          <div class="val-container">
            <div class="value started">{{ page.stats.habilitacoesLeilao.aprovado ?? 0 }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Solicitações reprovadas:</div>
          <div class="val-container">
            <div class="value neg">{{ page.stats.habilitacoesLeilao.reprovado ?? 0 }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Solicitações pendentes:</div>
          <div class="val-container">
            <div class="value pending">{{ page.stats.habilitacoesLeilao.analise ?? 0 }}</div>
          </div>
        </div>
        <div class="hr" />
        <div class="count m-t-lg">
          <div class="lbl">Direito de Preferência:</div>
          <div class="val-container">
            <div class="value abertas">{{ page.stats.habilitacoesLote.aprovado ?? 0 }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Solicitações de Preferência:</div>
          <div class="val-container">
            <div class="value pending">{{ page.stats.habilitacoesLote.solicitado ?? 0 }}</div>
          </div>
        </div>
<!--        <div class="count">
          <div class="lbl">Fechadas hoje:</div>
          <div class="val-container">
            <div class="value fechadas">{{ stats.fechadasHoje }}</div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {UFakeLoader} from "uloc-vue"

export default {
  name: "ResumoHabilitacoes",
  mixins: [tabMixin],
  components: {
    UFakeLoader
  },
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
