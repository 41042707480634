import http from '../../../utils/services/http'

export const list = (limit, page, filtros, format = null) => {
    let url = '/api/leiloes/documentos?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (format && format !== 'html') {
        config.responseType = 'blob'
        config.timeout = 30000
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
