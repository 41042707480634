<template>
  <div class="ev2-box dash-marketplace">
    <div class="header">
      <div class="hcol1">
        <div class="title">Marketplace</div>
        <div class="subtitle">Informações sobre o anúncio do leilão</div>
      </div>
      <div class="hcol2">
      </div>
    </div>
    <div class="body no-overflow m-t-md">
      <div class="tab1">
      <e-row>
        <e-col>
          <div class="lbl">Anúncio</div>
          <div class="val font-bold m-r">
            {{leilao.titulo}}
          </div>
        </e-col>
        <e-col>
          <div class="lbl">Destaque</div>
          <div class="val ok" v-if="leilao.destaque">Sim</div>
          <div class="val nok" v-else>Não</div>
        </e-col>
        <e-col class="text-right">
          <u-btn @click="leilaoWindow(leilao.id)" class="f-btn bg-blue-grey" no-caps label="Definições de publicação" />
        </e-col>
      </e-row>
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import leilaoWindow from "components/leiloes/windows/leilao"

export default {
  name: "ResumoMarketplace",
  components: {
    ECol,
    ERow
  },
  mixins: [tabMixin],
  methods: {
    leilaoWindow: leilaoWindow
  }
}
</script>
