<template>
  <div class="ev2-box docs hscroll">
    <div class="header">
      <div class="hcol1">
        <div class="title">Documentos do Leilão</div>
        <div class="subtitle">Documentos gerados ou anexos</div>
      </div>
      <div class="hcol2">
        <erp-input v-model="busca" @keydown.enter="load" class="sl-input input-sm" placeholder="Buscar" :before="[{icon: 'search', handler () {}}]" />
      </div>
    </div>
    <div class="body m-t">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table time-lista"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props" :props="props">
          <u-td class="td-limit" style="cursor: default; min-width: 150px" key="arquivo" :props="props">
            <a :href="props.row.url" target="_blank" class="doc-list-item cursor-pointer" @click="download($event, props.row)">
              <div class="icon">
                <u-icon :name="getFileIco(props.row.filename)" type="fa" icon-style="light" />
              </div>
              <div class="lbl">
                  <div class="filename">{{String(props.row.nome).toLowerCase()}}</div>
                  <div class="file-desc">
                    {{getFileExtension(props.row.filename)}}<span v-if="props.row.size"> / {{filesize(props.row.size)}}</span>
                  </div>
              </div>
            </a>
          </u-td>
          <u-td style="cursor: default; min-width: 150px" key="registro" :props="props">
            <div>{{props.row.createdAt|formatDate}}</div>
            <div class="text-blue-grey-3 font-10 m-t-xxs">por {{props.row.createdByName}}</div>
          </u-td>
          <u-td style="cursor: default; min-width: 150px" key="permissao" :props="props">
            <!--  0 = Publico - 1 = Arrematantes Cadastrados - 2 = Comitentes - 100 = Privado -->
            <div v-if="props.row.permissao === 0">Público</div>
            <div v-if="props.row.permissao === 1">Arrematantes</div>
            <div v-if="props.row.permissao === 2">Comitentes</div>
            <div v-if="props.row.permissao === 100">Privado</div>
          </u-td>
          <u-td style="cursor: default; min-width: 150px" key="downloads" :props="props">
            <!--{{props.row.downloads|abreviarNumero}}-->
            Indisponível
          </u-td>
          <u-td class="text-right" key="options" :props="props">
            <div class="cursor-pointer">
              <div class="text-right no-select">Ações <i class="fa fa-chevron-down text-grey-5 font-10" /></div>
              <u-popover class="window-context-menu menu-profile min" anchor="bottom right" self="top right">
                <ul>
                  <menu-options-item close label="Baixar" @click="download($event, props.row, true)" />
                  <menu-options-item close label="Excluir" @click="excluir(props.row)" />
                </ul>
              </u-popover>
            </div>
          </u-td>
        </u-tr>
      </u-table>
    </div>
    <div class="footer m-t">
      <div class="">
        <u-btn @click="uploadDocumento(leilao)" class="f-btn" no-caps label="Anexar Documento" />
        <u-btn @click="() => gerarDocumentoWindow('leilao', this.leilao.id)" class="f-btn m-l bg-blue-grey" no-caps label="Gerar documento" />
      </div>
    </div>
  </div>
</template>

<script>
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTr, UTh, UTd, date, UPopover} from "uloc-vue"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import {list} from "@/domain/leiloes/services/documentos"
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import fileType from "@/mixins/fileType"
import ArquivosLeilaoMixin from "components/leiloes/components/include/ArquivosLeilaoMixin"

let filters = {
  id: null,
}

const listName = 'table.leilao.dash.documentos'
const listStorage = tableColumnsCache(listName, [
  {label: 'Arquivo', name: 'arquivo', active: true, sortable: true, ordering: 1},
  {label: 'Registro', name: 'registro', active: true, sortable: true, ordering: 2},
  {label: 'Permissão', name: 'permissao', active: true, sortable: true, ordering: 3},
  {label: 'Downloads', name: 'downloads', active: true, sortable: true, ordering: 4},
], 2)
export default {
  name: "DocumentosLeilao",
  mixins: [tabMixin, fileType, ArquivosLeilaoMixin],
  components: {
    MenuOptionsItem,
    //ErpSelect,
    ErpInput,
    UTable,
    UTr,
    UTh,
    UTd,
    UPopover
  },
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    return {
      busca: null,
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {

      console.log('XXX')
      //this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []
      extraFilters.push(`&leilao=${this.leilao.id}`)
      this.busca && extraFilters.push(`&search=${this.busca}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then((response) => {
            const data = response.data
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
